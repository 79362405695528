.checkbox { position: relative; }
.checkbox input {
    opacity: 0;
    position: absolute;
}
.checkbox label { 
    display: inline-flex; 
    line-height: 1.4;
    cursor: pointer;
    position: relative;
}
.checkbox label:before {
    display: block;
    content: '';
    width: 24px;
    height: 24px;
    border: 1px solid #fff;
    border-radius: 4px;
    margin-right: 10px;
    order: -1;
    background-color: transparent;
}
.checkboxLabel {
    flex: 1;
    line-height: 1.4;
}

.checkbox label svg {
    position: absolute;
    left: 5px;
    top: 5px;
    pointer-events: none;
}

.checkbox input:checked + label:before {
    background-color: #fff;
}