@charset "UTF-8";
.uppy-Informer {
  position: absolute;
  right: 0;
  bottom: 60px;
  left: 0;
  z-index: 1005;
  text-align: center;
}
.uppy-Informer span > div {
  margin-bottom: 6px;
}

.uppy-Informer-animated {
  z-index: -1000;
  transform: translateY(350%);
  opacity: 0;
  transition: all 300ms ease-in;
}

.uppy-Informer p {
  display: inline-block;
  max-width: 90%;
  margin: 0;
  padding: 0;
  padding: 6px 15px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 1.4;
  background-color: #757575;
  border-radius: 18px;
}

[data-uppy-theme=dark] .uppy-Informer p {
  background-color: #333;
}

[dir="ltr"] .uppy-Informer p span{
  left: 3px;
}

[dir="rtl"] .uppy-Informer p span{
  right: 3px;
}

[dir="ltr"] .uppy-Informer p span{
  margin-left: -1px;
}

[dir="rtl"] .uppy-Informer p span{
  margin-right: -1px;
}

.uppy-Informer p span {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 13px;
  height: 13px;
  color: #525252;
  font-size: 10px;
  line-height: 12px;
  vertical-align: middle;
  background-color: #fff;
  border-radius: 50%;
}

.uppy-Informer p span:hover {
  cursor: help;
}

.uppy-Informer p span::after {
  line-height: 1.3;
  word-wrap: break-word;
}

/* -------------------------------------------------------------------
  Microtip

  Modern, lightweight css-only tooltips
  Just 1kb minified and gzipped

  @author Ghosh
  @package Microtip

----------------------------------------------------------------------
  1. Base Styles
  2. Direction Modifiers
  3. Position Modifiers
-------------------------------------------------------------------- */
/* ------------------------------------------------
  [1] Base Styles
------------------------------------------------- */
.uppy-Root [aria-label][role~=tooltip] {
  position: relative;
}

.uppy-Root [aria-label][role~=tooltip]::before,
.uppy-Root [aria-label][role~=tooltip]::after {
  position: absolute;
  z-index: 10;
  box-sizing: border-box;
  transform: translate3d(0, 0, 0);
  transform-origin: top;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  opacity: 0;
  transition: all var(--microtip-transition-duration, 0.18s) var(--microtip-transition-easing, ease-in-out) var(--microtip-transition-delay, 0s);
  pointer-events: none;
  will-change: transform;
}

.uppy-Root [aria-label][role~=tooltip]::before {
  background-size: 100% auto !important;
  content: "";
}

.uppy-Root [aria-label][role~=tooltip]::after {
  box-sizing: content-box;
  padding: 0.5em 1em;
  color: #fff;
  font-weight: var(--microtip-font-weight, normal);
  font-size: var(--microtip-font-size, 13px);
  white-space: nowrap;
  text-transform: var(--microtip-text-transform, none);
  background: rgba(17, 17, 17, 0.9);
  border-radius: 4px;
  content: attr(aria-label);
}

.uppy-Root [aria-label][role~=tooltip]:hover::before,
.uppy-Root [aria-label][role~=tooltip]:hover::after,
.uppy-Root [aria-label][role~=tooltip]:focus::before,
.uppy-Root [aria-label][role~=tooltip]:focus::after {
  opacity: 1;
  pointer-events: auto;
}

/* ------------------------------------------------
  [2] Position Modifiers
------------------------------------------------- */
.uppy-Root [role~=tooltip][data-microtip-position|=top]::before {
  bottom: 100%;
  left: 50%;
  width: 18px;
  height: 6px;
  margin-bottom: 5px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%280%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position|=top]::after {
  bottom: 100%;
  left: 50%;
  margin-bottom: 11px;
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position|=top]:hover::before {
  transform: translate3d(-50%, -5px, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position=top]:hover::after {
  transform: translate3d(-50%, -5px, 0);
}

/* ------------------------------------------------
  [2.1] Top Left
------------------------------------------------- */
.uppy-Root [role~=tooltip][data-microtip-position=top-left]::after {
  bottom: 100%;
  transform: translate3d(calc(-100% + 16px), 0, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position=top-left]:hover::after {
  transform: translate3d(calc(-100% + 16px), -5px, 0);
}

/* ------------------------------------------------
  [2.2] Top Right
------------------------------------------------- */
.uppy-Root [role~=tooltip][data-microtip-position=top-right]::after {
  bottom: 100%;
  transform: translate3d(calc(0% - 16px), 0, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position=top-right]:hover::after {
  transform: translate3d(calc(0% - 16px), -5px, 0);
}

/* ------------------------------------------------
  [2.3] Bottom
------------------------------------------------- */
.uppy-Root [role~=tooltip][data-microtip-position|=bottom]::before {
  top: 100%;
  bottom: auto;
  left: 50%;
  width: 18px;
  height: 6px;
  margin-top: 5px;
  margin-bottom: 0;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2236px%22%20height%3D%2212px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28180%2018%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
  transform: translate3d(-50%, -10px, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position|=bottom]::after {
  top: 100%;
  left: 50%;
  margin-top: 11px;
  transform: translate3d(-50%, -10px, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position|=bottom]:hover::before {
  transform: translate3d(-50%, 0, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position=bottom]:hover::after {
  transform: translate3d(-50%, 0, 0);
}

/* ------------------------------------------------
  [2.4] Bottom Left
------------------------------------------------- */
.uppy-Root [role~=tooltip][data-microtip-position=bottom-left]::after {
  top: 100%;
  transform: translate3d(calc(-100% + 16px), -10px, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position=bottom-left]:hover::after {
  transform: translate3d(calc(-100% + 16px), 0, 0);
}

/* ------------------------------------------------
  [2.5] Bottom Right
------------------------------------------------- */
.uppy-Root [role~=tooltip][data-microtip-position=bottom-right]::after {
  top: 100%;
  transform: translate3d(calc(0% - 16px), -10px, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position=bottom-right]:hover::after {
  transform: translate3d(calc(0% - 16px), 0, 0);
}

/* ------------------------------------------------
  [2.6] Left
------------------------------------------------- */
.uppy-Root [role~=tooltip][data-microtip-position=left]::before,
.uppy-Root [role~=tooltip][data-microtip-position=left]::after {
  top: 50%;
  right: 100%;
  bottom: auto;
  left: auto;
  transform: translate3d(10px, -50%, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position=left]::before {
  width: 6px;
  height: 18px;
  margin-right: 5px;
  margin-bottom: 0;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%28-90%2018%2018%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
}

.uppy-Root [role~=tooltip][data-microtip-position=left]::after {
  margin-right: 11px;
}

.uppy-Root [role~=tooltip][data-microtip-position=left]:hover::before,
.uppy-Root [role~=tooltip][data-microtip-position=left]:hover::after {
  transform: translate3d(0, -50%, 0);
}

/* ------------------------------------------------
  [2.7] Right
------------------------------------------------- */
.uppy-Root [role~=tooltip][data-microtip-position=right]::before,
.uppy-Root [role~=tooltip][data-microtip-position=right]::after {
  top: 50%;
  bottom: auto;
  left: 100%;
  transform: translate3d(-10px, -50%, 0);
}

.uppy-Root [role~=tooltip][data-microtip-position=right]::before {
  width: 6px;
  height: 18px;
  margin-bottom: 0;
  margin-left: 5px;
  background: url("data:image/svg+xml;charset=utf-8,%3Csvg%20xmlns%3D%22http%3A//www.w3.org/2000/svg%22%20width%3D%2212px%22%20height%3D%2236px%22%3E%3Cpath%20fill%3D%22rgba%2817,%2017,%2017,%200.9%29%22%20transform%3D%22rotate%2890%206%206%29%22%20d%3D%22M2.658,0.000%20C-13.615,0.000%2050.938,0.000%2034.662,0.000%20C28.662,0.000%2023.035,12.002%2018.660,12.002%20C14.285,12.002%208.594,0.000%202.658,0.000%20Z%22/%3E%3C/svg%3E") no-repeat;
}

.uppy-Root [role~=tooltip][data-microtip-position=right]::after {
  margin-left: 11px;
}

.uppy-Root [role~=tooltip][data-microtip-position=right]:hover::before,
.uppy-Root [role~=tooltip][data-microtip-position=right]:hover::after {
  transform: translate3d(0, -50%, 0);
}

/* ------------------------------------------------
  [3] Size
------------------------------------------------- */
.uppy-Root [role~=tooltip][data-microtip-size=small]::after {
  width: 80px;
  white-space: initial;
}

.uppy-Root [role~=tooltip][data-microtip-size=medium]::after {
  width: 150px;
  white-space: initial;
}

.uppy-Root [role~=tooltip][data-microtip-size=large]::after {
  width: 260px;
  white-space: initial;
}

.uppy-StatusBar {
  position: relative;
  z-index: 1001;
  display: flex;
  height: 46px;
  color: #fff;
  font-weight: 400;
  font-size: 12px;
  line-height: 40px;
  background-color: #fff;
  transition: height 0.2s;
}
[data-uppy-theme=dark] .uppy-StatusBar {
  background-color: #1f1f1f;
}

.uppy-StatusBar::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background-color: #eaeaea;
  content: "";
}
[data-uppy-theme=dark] .uppy-StatusBar::before {
  background-color: #757575;
}

.uppy-StatusBar[aria-hidden=true] {
  height: 0;
  overflow-y: hidden;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-progress {
  background-color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-progress {
  background-color: #e32437;
}

.uppy-StatusBar.is-complete .uppy-StatusBar-statusIndicator {
  color: #1bb240;
}

.uppy-StatusBar.is-error .uppy-StatusBar-statusIndicator {
  color: #e32437;
}

.uppy-StatusBar:not([aria-hidden=true]).is-waiting {
  height: 65px;
  background-color: #fff;
  border-top: 1px solid #eaeaea;
}
[data-uppy-theme=dark] .uppy-StatusBar:not([aria-hidden=true]).is-waiting {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

.uppy-StatusBar-progress {
  position: absolute;
  z-index: 1001;
  height: 2px;
  background-color: #2275d7;
  transition: background-color, width 0.3s ease-out;
}
.uppy-StatusBar-progress.is-indeterminate {
  background-image: linear-gradient(45deg, rgba(0, 0, 0, 0.3) 25%, transparent 25%, transparent 50%, rgba(0, 0, 0, 0.3) 50%, rgba(0, 0, 0, 0.3) 75%, transparent 75%, transparent);
  background-size: 64px 64px;
  animation: uppy-StatusBar-ProgressStripes 1s linear infinite;
}

@keyframes uppy-StatusBar-ProgressStripes {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 64px 0;
  }
}
.uppy-StatusBar.is-preprocessing .uppy-StatusBar-progress,
.uppy-StatusBar.is-postprocessing .uppy-StatusBar-progress {
  background-color: #f6a623;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-progress {
  display: none;
}

[dir="ltr"] .uppy-StatusBar-content{
  padding-left: 10px;
}

[dir="rtl"] .uppy-StatusBar-content{
  padding-right: 10px;
}

.uppy-StatusBar-content {
  position: relative;
  z-index: 1002;
  display: flex;
  align-items: center;
  height: 100%;
  color: #333;
  white-space: nowrap;
  text-overflow: ellipsis;
}

[data-uppy-theme=dark] .uppy-StatusBar-content {
  color: #eaeaea;
}

[dir="ltr"] .uppy-StatusBar-status{
  padding-right: 0.3em;
}

[dir="rtl"] .uppy-StatusBar-status{
  padding-left: 0.3em;
}

.uppy-StatusBar-status {
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-weight: normal;
  line-height: 1.4;
}

.uppy-StatusBar-statusPrimary {
  font-weight: 500;
  line-height: 1;
}
[data-uppy-theme=dark] .uppy-StatusBar-statusPrimary {
  color: #eaeaea;
}

.uppy-StatusBar-statusSecondary {
  display: inline-block;
  margin-top: 1px;
  color: #757575;
  font-size: 11px;
  line-height: 1.2;
  white-space: nowrap;
}
[data-uppy-theme=dark] .uppy-StatusBar-statusSecondary {
  color: #bbb;
}

[dir="ltr"] .uppy-StatusBar-statusSecondaryHint{
  margin-right: 5px;
}

[dir="rtl"] .uppy-StatusBar-statusSecondaryHint{
  margin-left: 5px;
}

.uppy-StatusBar-statusSecondaryHint {
  display: inline-block;
  line-height: 1;
  vertical-align: middle;
}


[dir="ltr"] .uppy-StatusBar-statusIndicator{
  margin-right: 7px;
}

[dir="rtl"] .uppy-StatusBar-statusIndicator{
  margin-left: 7px;
}

.uppy-StatusBar-statusIndicator {
  position: relative;
  top: 1px;
  color: #525252;
}
.uppy-StatusBar-statusIndicator svg {
  vertical-align: text-bottom;
}

[dir="ltr"] .uppy-StatusBar-actions{
  right: 10px;
}

[dir="rtl"] .uppy-StatusBar-actions{
  left: 10px;
}

.uppy-StatusBar-actions {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1004;
  display: flex;
  align-items: center;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  position: static;
  width: 100%;
  height: 100%;
  padding: 0 15px;
  background-color: #fafafa;
}
[data-uppy-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actions {
  background-color: #1f1f1f;
}

.uppy-StatusBar:not([aria-hidden=true]).is-waiting.has-ghosts {
  flex-direction: column;
  height: 90px;
}

.uppy-StatusBar:not([aria-hidden=true]).is-waiting.has-ghosts .uppy-StatusBar-actions {
  flex-direction: column;
  justify-content: center;
}


.uppy-StatusBar-actionCircleBtn {
  margin: 3px;
  line-height: 1;
  cursor: pointer;
  opacity: 0.9;
}
.uppy-StatusBar-actionCircleBtn:focus {
  outline: none;
}
.uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
  border: 0;
}
.uppy-StatusBar-actionCircleBtn:focus {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
[data-uppy-theme=dark] .uppy-StatusBar-actionCircleBtn:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionCircleBtn::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionCircleBtn:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}
.uppy-StatusBar-actionCircleBtn:hover {
  opacity: 1;
}
.uppy-StatusBar-actionCircleBtn:focus {
  border-radius: 50%;
}

.uppy-StatusBar-actionCircleBtn svg {
  vertical-align: bottom;
}

.uppy-StatusBar-actionBtn {
  display: inline-block;
  color: #2275d7;
  font-size: 10px;
  line-height: inherit;
  vertical-align: middle;
}


.uppy-StatusBar-actionBtn--disabled {
  opacity: 0.4;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--disabled {
  opacity: 0.7;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--retry{
  margin-right: 6px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--retry{
  margin-left: 6px;
}

.uppy-StatusBar-actionBtn--retry {
  position: relative;
  height: 16px;
  padding: 1px 6px 3px 18px;
  color: #fff;
  line-height: 1;
  background-color: #ff4b23;
  border-radius: 8px;
}
.uppy-StatusBar-actionBtn--retry:focus {
  outline: none;
}
.uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
  border: 0;
}
.uppy-StatusBar-actionBtn--retry:focus {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--retry:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--retry::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--retry:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}
.uppy-StatusBar-actionBtn--retry:hover {
  background-color: #f92d00;
}
[dir="ltr"] .uppy-StatusBar-actionBtn--retry svg{
  left: 6px;
}
[dir="rtl"] .uppy-StatusBar-actionBtn--retry svg{
  right: 6px;
}
.uppy-StatusBar-actionBtn--retry svg {
  position: absolute;
  top: 3px;
}

.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  width: 100%;
  padding: 15px 10px;
  color: #fff;
  font-size: 14px;
  line-height: 1;
  background-color: #1bb240;
}
.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #189c38;
}
[data-uppy-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload {
  background-color: #1c8b37;
}
[data-uppy-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload:hover {
  background-color: #18762f;
}



.uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  background-color: #1bb240;
  cursor: not-allowed;
}

[data-uppy-theme=dark] .uppy-StatusBar.is-waiting .uppy-StatusBar-actionBtn--upload.uppy-StatusBar-actionBtn--disabled:hover {
  background-color: #1c8b37;
}

.uppy-StatusBar:not(.is-waiting) .uppy-StatusBar-actionBtn--upload {
  color: #2275d7;
  background-color: transparent;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--uploadNewlyAdded{
  padding-right: 3px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--uploadNewlyAdded{
  padding-left: 3px;
}

[dir="ltr"] .uppy-StatusBar-actionBtn--uploadNewlyAdded{
  padding-left: 3px;
}

[dir="rtl"] .uppy-StatusBar-actionBtn--uploadNewlyAdded{
  padding-right: 3px;
}

.uppy-StatusBar-actionBtn--uploadNewlyAdded {
  padding-bottom: 1px;
  border-radius: 3px;
}
.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  outline: none;
}
.uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
  border: 0;
}
.uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--uploadNewlyAdded::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--uploadNewlyAdded:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}

.uppy-StatusBar-actionBtn--done {
  padding: 7px 8px;
  line-height: 1;
  border-radius: 3px;
}
.uppy-StatusBar-actionBtn--done:focus {
  outline: none;
}
.uppy-StatusBar-actionBtn--done::-moz-focus-inner {
  border: 0;
}
.uppy-StatusBar-actionBtn--done:hover {
  color: #1b5dab;
}
.uppy-StatusBar-actionBtn--done:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--done:focus {
  background-color: #333;
}
[data-uppy-theme=dark] .uppy-StatusBar-actionBtn--done {
  color: #02baf2;
}



.uppy-StatusBar-serviceMsg {
  padding-left: 10px;
  color: #000;
  font-size: 11px;
  line-height: 1.1;
}

[data-uppy-theme=dark] .uppy-StatusBar-serviceMsg {
  color: #eaeaea;
}

.uppy-StatusBar-serviceMsg-ghostsIcon {
  position: relative;
  top: 2px;
  left: 6px;
  width: 10px;
  vertical-align: text-bottom;
  opacity: 0.5;
}


[dir="ltr"] .uppy-StatusBar-details{
  left: 2px;
}

[dir="rtl"] .uppy-StatusBar-details{
  right: 2px;
}

.uppy-StatusBar-details {
  position: relative;
  top: 0;
  display: inline-block;
  width: 13px;
  height: 13px;
  color: #fff;
  font-weight: 600;
  font-size: 10px;
  line-height: 12px;
  text-align: center;
  vertical-align: middle;
  background-color: #939393;
  border-radius: 50%;
  cursor: help;
  -webkit-appearance: none;
          appearance: none;
}

.uppy-StatusBar-details::after {
  line-height: 1.3;
  word-wrap: break-word;
}

[dir="ltr"] .uppy-StatusBar-spinner{
  margin-right: 10px;
}

[dir="rtl"] .uppy-StatusBar-spinner{
  margin-left: 10px;
}

.uppy-StatusBar-spinner {
  animation-name: uppy-StatusBar-spinnerAnimation;
  animation-duration: 1s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  fill: #2275d7;
}

.uppy-StatusBar.is-preprocessing .uppy-StatusBar-spinner,
.uppy-StatusBar.is-postprocessing .uppy-StatusBar-spinner {
  fill: #f6a623;
}

@keyframes uppy-StatusBar-spinnerAnimation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list,
.uppy-ProviderBrowser-viewType--unsplash ul.uppy-ProviderBrowser-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  padding: 6px;
}
.uppy-ProviderBrowser-viewType--grid ul.uppy-ProviderBrowser-list::after,
.uppy-ProviderBrowser-viewType--unsplash ul.uppy-ProviderBrowser-list::after {
  flex: auto;
  content: "";
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  position: relative;
  width: 50%;
  margin: 0;
}

.uppy-size--lg .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem,
.uppy-size--lg .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem {
  width: 25%;
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem::before,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem::before {
  display: block;
  padding-top: 100%;
  content: "";
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected img,
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--selected svg,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--selected img,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--selected svg {
  opacity: 0.85;
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--disabled,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--disabled {
  opacity: 0.5;
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: rgba(147, 147, 147, 0.2);
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner,
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview .uppy-ProviderBrowserItem-inner {
  background-color: rgba(234, 234, 234, 0.2);
}
.uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg,
.uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview svg {
  width: 30%;
  height: 30%;
  fill: rgba(0, 0, 0, 0.7);
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--grid li.uppy-ProviderBrowserItem--noPreview svg,
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--unsplash li.uppy-ProviderBrowserItem--noPreview svg {
  fill: rgba(255, 255, 255, 0.8);
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner {
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  left: 7px;
  width: calc(100% - 14px);
  height: calc(100% - 14px);
  overflow: hidden;
  text-align: center;
  border-radius: 4px;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy.uppy-ProviderBrowserItem-inner-relative,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy.uppy-ProviderBrowserItem-inner-relative {
  position: relative;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author {
  position: absolute;
  display: none;
  bottom: 0;
  left: 0;
  width: 100%;
  background: rgba(0, 0, 0, 0.3);
  color: white;
  font-weight: 500;
  font-size: 12px;
  margin: 0;
  padding: 5px;
  text-decoration: none;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author:hover,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author:hover {
  background: rgba(0, 0, 0, 0.4);
  text-decoration: underline;
}
@media (hover: none) {
  .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner .uppy-ProviderBrowserItem-author {
    display: block;
  }
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner,
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner {
  box-shadow: 0 0 0 3px rgba(170, 225, 255, 0.7);
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-inner img,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-inner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 4px;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox {
  position: absolute;
  top: 16px;
  right: 16px;
  z-index: 1002;
  width: 26px;
  height: 26px;
  background-color: #2275d7;
  border-radius: 50%;
  opacity: 0;
}
[dir="ltr"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox::after,[dir="ltr"] 
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox::after{
  left: 7px;
}
[dir="rtl"] .uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox::after,[dir="rtl"] 
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox::after{
  right: 7px;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox::after,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox::after {
  top: 8px;
  width: 12px;
  height: 7px;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--is-checked,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--is-checked {
  opacity: 1;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:hover + label .uppy-ProviderBrowserItem-author,
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label .uppy-ProviderBrowserItem-author,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:hover + label .uppy-ProviderBrowserItem-author,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label .uppy-ProviderBrowserItem-author {
  display: block;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label:focus,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label:focus {
  outline: none;
}
.uppy-ProviderBrowser-viewType--grid .uppy-ProviderBrowserItem-checkbox--grid:focus + label::-moz-focus-inner,
.uppy-ProviderBrowser-viewType--unsplash .uppy-ProviderBrowserItem-checkbox--grid:focus + label::-moz-focus-inner {
  border: 0;
}

.uppy-ProviderBrowser-viewType--list {
  background-color: #fff;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--list {
  background-color: #1f1f1f;
}
.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  display: flex;
  align-items: center;
  margin: 0;
  padding: 7px 15px;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem {
  color: #eaeaea;
}
.uppy-ProviderBrowser-viewType--list li.uppy-ProviderBrowserItem--disabled {
  opacity: 0.6;
}
[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox{
  margin-right: 15px;
}
[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox{
  margin-left: 15px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox {
  width: 17px;
  height: 17px;
  background-color: #fff;
  border: 1px solid #cfcfcf;
  border-radius: 3px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:focus {
  border: 1px solid #2275d7;
  outline: none;
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.25);
}
[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox::after{
  left: 3px;
}
[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox::after{
  right: 3px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox::after {
  top: 4px;
  width: 9px;
  height: 5px;
  opacity: 0;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox:focus {
  border-color: rgba(2, 186, 242, 0.7);
  box-shadow: 0 0 0 3px rgba(2, 186, 242, 0.2);
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox--is-checked {
  background-color: #2275d7;
  border-color: #2275d7;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-checkbox--is-checked::after {
  opacity: 1;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner {
  display: flex;
  align-items: center;
  padding: 2px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner:focus {
  text-decoration: underline;
  outline: none;
}
[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img,[dir="ltr"] 
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg{
  margin-right: 8px;
}
[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img,[dir="rtl"] 
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg{
  margin-left: 8px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner img,
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner svg {
  max-width: 20px;
  max-height: 20px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-inner span {
  overflow: hidden;
  line-height: 1.2;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem--disabled .uppy-ProviderBrowserItem-inner {
  cursor: default;
}
[dir="ltr"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap{
  margin-right: 7px;
}
[dir="rtl"] .uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap{
  margin-left: 7px;
}
.uppy-ProviderBrowser-viewType--list .uppy-ProviderBrowserItem-iconWrap {
  width: 20px;
}

.uppy-ProviderBrowserItem-checkbox {
  position: relative;
  flex-shrink: 0;
  cursor: pointer;
}
.uppy-ProviderBrowserItem-checkbox:disabled {
  cursor: default;
}
.uppy-ProviderBrowserItem-checkbox::after {
  position: absolute;
  border-bottom: 2px solid #eaeaea;
  border-left: 2px solid #eaeaea;
  transform: rotate(-45deg);
  cursor: pointer;
  content: "";
}
.uppy-ProviderBrowserItem-checkbox:disabled::after {
  cursor: default;
}
[data-uppy-theme=dark] .uppy-ProviderBrowserItem-checkbox {
  background-color: #1f1f1f;
  border-color: #939393;
}

[data-uppy-theme=dark] .uppy-ProviderBrowserItem-checkbox--is-checked {
  background-color: #333;
}

.uppy-SearchProvider {
  display: flex;
  flex: 1;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}
[data-uppy-theme=dark] .uppy-SearchProvider {
  background-color: #1f1f1f;
}

.uppy-SearchProvider-input {
  width: 90%;
  max-width: 650px;
  margin-bottom: 15px;
}


.uppy-SearchProvider-searchButton {
  padding: 13px 25px;
}


.uppy-DashboardContent-panelBody {
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
}
[data-uppy-theme=dark] .uppy-DashboardContent-panelBody {
  background-color: #1f1f1f;
}

.uppy-Provider-auth,
.uppy-Provider-error,
.uppy-Provider-loading,
.uppy-Provider-empty {
  display: flex;
  flex: 1;
  flex-flow: column wrap;
  align-items: center;
  justify-content: center;
  color: #939393;
}

.uppy-Provider-empty {
  color: #939393;
}

.uppy-Provider-authIcon svg {
  width: 100px;
  height: 75px;
  margin-bottom: 15px;
}

.uppy-Provider-authTitle {
  max-width: 500px;
  margin-bottom: 30px;
  padding: 0 15px;
  color: #757575;
  font-weight: 400;
  font-size: 17px;
  line-height: 1.4;
  text-align: center;
}

[data-uppy-theme=dark] .uppy-Provider-authTitle {
  color: #cfcfcf;
}

.uppy-Provider-btn-google {
  display: flex;
  align-items: center;
  padding: 8px 12px !important;
  background: #4285F4;
}
.uppy-Provider-btn-google:hover {
  background-color: #1266f1;
}
.uppy-Provider-btn-google:focus {
  outline: none;
  box-shadow: 0 0 0 3px rgba(66, 133, 244, 0.4);
}
.uppy-Provider-btn-google svg {
  margin-right: 8px;
}

[dir="ltr"] .uppy-Provider-breadcrumbs{
  text-align: left;
}

[dir="rtl"] .uppy-Provider-breadcrumbs{
  text-align: right;
}

.uppy-Provider-breadcrumbs {
  flex: 1;
  margin-bottom: 10px;
  color: #525252;
  font-size: 12px;
}

[data-uppy-theme=dark] .uppy-Provider-breadcrumbs {
  color: #eaeaea;
}

[dir="ltr"] .uppy-Provider-breadcrumbsIcon{
  margin-right: 4px;
}

[dir="rtl"] .uppy-Provider-breadcrumbsIcon{
  margin-left: 4px;
}

.uppy-Provider-breadcrumbsIcon {
  display: inline-block;
  color: #525252;
  line-height: 1;
  vertical-align: middle;
}

.uppy-Provider-breadcrumbsIcon svg {
  width: 13px;
  height: 13px;
  fill: #525252;
}

.uppy-Provider-breadcrumbs button {
  display: inline-block;
  padding: 4px;
  line-height: inherit;
  border-radius: 3px;
}
.uppy-Provider-breadcrumbs button:focus {
  outline: none;
}
.uppy-Provider-breadcrumbs button::-moz-focus-inner {
  border: 0;
}
.uppy-Provider-breadcrumbs button:hover {
  color: #1b5dab;
}
.uppy-Provider-breadcrumbs button:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-Provider-breadcrumbs button:focus {
  background-color: #333;
}
.uppy-Provider-breadcrumbs button:not(:last-of-type) {
  text-decoration: underline;
}
.uppy-Provider-breadcrumbs button:last-of-type {
  color: #333;
  font-weight: 500;
  cursor: normal;
  pointer-events: none;
}
.uppy-Provider-breadcrumbs button:hover {
  cursor: pointer;
}
[data-uppy-theme=dark] .uppy-Provider-breadcrumbs button {
  color: #eaeaea;
}

.uppy-ProviderBrowser {
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  font-weight: 400;
  font-size: 14px;
}

.uppy-ProviderBrowser-user {
  margin: 0 8px 0 0;
  color: #333;
  font-weight: 500;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-user {
  color: #eaeaea;
}

[dir="ltr"] .uppy-ProviderBrowser-user::after{
  left: 4px;
}

[dir="rtl"] .uppy-ProviderBrowser-user::after{
  right: 4px;
}

.uppy-ProviderBrowser-user::after {
  position: relative;
  color: #939393;
  font-weight: normal;
  content: "·";
}

.uppy-ProviderBrowser-header {
  position: relative;
  z-index: 1001;
  border-bottom: 1px solid #eaeaea;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-header {
  border-bottom: 1px solid #333;
}

.uppy-ProviderBrowser-headerBar {
  z-index: 1001;
  padding: 7px 15px;
  color: #757575;
  font-size: 12px;
  line-height: 1.4;
  background-color: #fafafa;
}

[data-uppy-theme=dark] .uppy-ProviderBrowser-headerBar {
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-headerBar--simple {
  display: block;
  justify-content: center;
  text-align: center;
}

.uppy-ProviderBrowser-headerBar--simple .uppy-Provider-breadcrumbsWrap {
  display: inline-block;
  flex: none;
  vertical-align: middle;
}

.uppy-ProviderBrowser-search {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  height: 30px;
  margin-top: 10px;
  margin-bottom: 5px;
  background-color: #fff;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-search {
  background-color: #1f1f1f;
}

[dir="ltr"] .uppy-ProviderBrowser-searchIcon{
  left: 16px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchIcon{
  right: 16px;
}

.uppy-ProviderBrowser-searchIcon {
  position: absolute;
  z-index: 1002;
  width: 12px;
  height: 12px;
  color: #bbb;
}

[dir="ltr"] .uppy-ProviderBrowser-searchInput{
  padding-left: 27px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchInput{
  padding-right: 27px;
}

.uppy-ProviderBrowser-searchInput {
  z-index: 1001;
  width: 100%;
  height: 30px;
  margin: 0 8px;
  font-size: 12px;
  font-family: -apple-system, blinkmacsystemfont, "Segoe UI", helvetica, arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  line-height: 1.4;
  background-color: transparent;
  border: 0;
  border-radius: 4px;
  outline: 0;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-searchInput {
  color: #eaeaea;
  background-color: #1f1f1f;
}

.uppy-ProviderBrowser-searchInput:focus {
  background-color: #f4f4f4;
  outline: 0;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-searchInput:focus {
  background-color: #333;
}

[dir="ltr"] .uppy-ProviderBrowser-searchClose{
  right: 12px;
}

[dir="rtl"] .uppy-ProviderBrowser-searchClose{
  left: 12px;
}

.uppy-ProviderBrowser-searchClose {
  position: absolute;
  top: 4px;
  z-index: 1002;
  width: 22px;
  height: 22px;
  padding: 6px;
  color: #939393;
  cursor: pointer;
}
.uppy-ProviderBrowser-searchClose:hover {
  color: #757575;
}

.uppy-ProviderBrowser-searchClose svg {
  vertical-align: text-top;
}

.uppy-ProviderBrowser-searchInput::placeholder {
  color: #939393;
  opacity: 1;
}

.uppy-ProviderBrowser-userLogout {
  padding: 4px;
  color: #2275d7;
  line-height: inherit;
  border-radius: 3px;
  cursor: pointer;
}
.uppy-ProviderBrowser-userLogout:focus {
  outline: none;
}
.uppy-ProviderBrowser-userLogout::-moz-focus-inner {
  border: 0;
}
.uppy-ProviderBrowser-userLogout:hover {
  color: #1b5dab;
}
.uppy-ProviderBrowser-userLogout:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-userLogout:focus {
  background-color: #333;
}
.uppy-ProviderBrowser-userLogout:hover {
  text-decoration: underline;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-userLogout {
  color: #eaeaea;
}

.uppy-ProviderBrowser-body {
  position: relative;
  flex: 1;
}

.uppy-ProviderBrowser-list {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: block;
  flex: 1;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
  list-style: none;
  background-color: #fff;
  border-spacing: 0;
  -webkit-overflow-scrolling: touch;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-list {
  background-color: #1f1f1f;
}
.uppy-ProviderBrowser-list:focus {
  outline: none;
}

.uppy-ProviderBrowserItem-inner {
  font-weight: 500;
  font-size: 13px;
  cursor: pointer;
}

.uppy-ProviderBrowser-footer {
  display: flex;
  align-items: center;
  height: 65px;
  padding: 0 15px;
  background-color: #fff;
  border-top: 1px solid #eaeaea;
}
[dir="ltr"] .uppy-ProviderBrowser-footer button{
  margin-right: 8px;
}
[dir="rtl"] .uppy-ProviderBrowser-footer button{
  margin-left: 8px;
}
[data-uppy-theme=dark] .uppy-ProviderBrowser-footer {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

.uppy-Dashboard-Item-previewInnerWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: 3px;
  box-shadow: 0 0 2px 0 rgba(0, 0, 0, 0.4);
}


.uppy-Dashboard-Item-previewInnerWrap::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  display: none;
  background-color: rgba(0, 0, 0, 0.65);
  content: "";
}

.uppy-Dashboard-Item-previewLink {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1002;
}
.uppy-Dashboard-Item-previewLink:focus {
  box-shadow: inset 0 0 0 3px #76abe9;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-previewLink:focus {
  box-shadow: inset 0 0 0 3px #016c8d;
}

.uppy-Dashboard-Item-preview img.uppy-Dashboard-Item-previewImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 3px;
  transform: translateZ(0);
}

.uppy-Dashboard-Item-progress {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 1002;
  width: 120px;
  color: #fff;
  text-align: center;
  transform: translate(-50%, -50%);
  transition: all 0.35 ease;
}

.uppy-Dashboard-Item-progressIndicator {
  display: inline-block;
  width: 38px;
  height: 38px;
  opacity: 0.9;
}


button.uppy-Dashboard-Item-progressIndicator {
  cursor: pointer;
}
button.uppy-Dashboard-Item-progressIndicator:focus {
  outline: none;
}
button.uppy-Dashboard-Item-progressIndicator::-moz-focus-inner {
  border: 0;
}
button.uppy-Dashboard-Item-progressIndicator:focus .uppy-Dashboard-Item-progressIcon--bg,
button.uppy-Dashboard-Item-progressIndicator:focus .uppy-Dashboard-Item-progressIcon--retry {
  fill: #76abe9;
}

.uppy-Dashboard-Item-progressIcon--circle {
  width: 100%;
  height: 100%;
}

.uppy-Dashboard-Item-progressIcon--bg {
  stroke: rgba(255, 255, 255, 0.4);
}

.uppy-Dashboard-Item-progressIcon--progress {
  transition: stroke-dashoffset 0.5s ease-out;
  stroke: #fff;
}

.uppy-Dashboard-Item-progressIcon--play {
  transition: all 0.2s;
  fill: #fff;
  stroke: #fff;
}

.uppy-Dashboard-Item-progressIcon--cancel {
  transition: all 0.2s;
  fill: #fff;
}

.uppy-Dashboard-Item-progressIcon--pause {
  transition: all 0.2s;
  fill: #fff;
  stroke: #fff;
}

.uppy-Dashboard-Item-progressIcon--check {
  transition: all 0.2s;
  fill: #fff;
}

.uppy-Dashboard-Item-progressIcon--retry {
  fill: #fff;
}

[dir="ltr"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress{
  right: -8px;
}

[dir="rtl"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress{
  left: -8px;
}

[dir="ltr"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress{
  left: initial;
}

[dir="rtl"] .uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress{
  right: initial;
}

.uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progress {
  top: -9px;
  width: auto;
  transform: initial;
}

.uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-progressIndicator {
  width: 18px;
  height: 18px;
}


.uppy-Dashboard-Item.is-complete .uppy-Dashboard-Item-progressIndicator {
  width: 18px;
  height: 18px;
  opacity: 1;
}


.uppy-Dashboard-Item.is-processing .uppy-Dashboard-Item-progress {
  opacity: 0;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfo{
  padding-right: 5px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfo{
  padding-left: 5px;
}

.uppy-Dashboard-Item-name {
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  word-wrap: anywhere;
  word-break: break-all;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-name {
  color: #eaeaea;
}

.uppy-Dashboard-Item-author {
  color: #757575;
  vertical-align: bottom;
  font-size: 11px;
  font-weight: normal;
  display: inline-block;
  line-height: 1;
}
.uppy-Dashboard-Item-author a {
  color: #757575;
}

.uppy-Dashboard-Item-status {
  color: #757575;
  font-weight: normal;
  font-size: 11px;
  line-height: 1;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-status {
  color: #bbb;
}

.uppy-Dashboard-Item-statusSize {
  display: inline-block;
  text-transform: uppercase;
  vertical-align: bottom;
}

.uppy-Dashboard-Item-reSelect {
  color: #2275d7;
  font-weight: 600;
  font-size: inherit;
  font-family: inherit;
}

.uppy-Dashboard-Item-action {
  color: #939393;
  cursor: pointer;
}
.uppy-Dashboard-Item-action:focus {
  outline: none;
}
.uppy-Dashboard-Item-action::-moz-focus-inner {
  border: 0;
}
.uppy-Dashboard-Item-action:focus {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
.uppy-Dashboard-Item-action:hover {
  color: #1f1f1f;
  opacity: 1;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action {
  color: #cfcfcf;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action:focus {
  outline: none;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action::-moz-focus-inner {
  border: 0;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action:focus {
  box-shadow: 0 0 0 2px rgba(170, 225, 255, 0.85);
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action:hover {
  color: #eaeaea;
}

.uppy-Dashboard-Item-action--remove {
  color: #1f1f1f;
  opacity: 0.95;
}
.uppy-Dashboard-Item-action--remove:hover {
  color: #000;
  opacity: 1;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action--remove {
  color: #525252;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item-action--remove:hover {
  color: #333;
}



[dir="ltr"] .uppy-Dashboard-Item{
  padding-right: 0;
}

[dir="rtl"] .uppy-Dashboard-Item{
  padding-left: 0;
}

.uppy-Dashboard-Item {
  display: flex;
  align-items: center;
  padding: 10px;
  border-bottom: 1px solid #eaeaea;
}
[data-uppy-theme=dark] .uppy-Dashboard-Item {
  border-bottom: 1px solid #333;
}

.uppy-size--lg .uppy-Dashboard-Item {
  /* When changing width: also update `itemsPerRow` values in `src/components/Dashboard.js`. */
  width: calc(25% - 15px - 15px);
  height: 190px;
  margin: 5px 15px;
}
.uppy-size--xl .uppy-Dashboard-Item {
  /* When changing width: also update `itemsPerRow` values in `src/components/Dashboard.js`. */
  width: calc(20% - 15px - 15px);
  height: 210px;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-previewInnerWrap {
  opacity: 0.2;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-name {
  opacity: 0.7;
}

.uppy-Dashboard-Item.is-ghost .uppy-Dashboard-Item-preview::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='35' height='39' viewBox='0 0 35 39'%3E%3Cpath d='M1.708 38.66c1.709 0 3.417-3.417 6.834-3.417 3.416 0 5.125 3.417 8.61 3.417 3.348 0 5.056-3.417 8.473-3.417 4.305 0 5.125 3.417 6.833 3.417.889 0 1.709-.889 1.709-1.709v-19.68C34.167-5.757 0-5.757 0 17.271v19.68c0 .82.888 1.709 1.708 1.709zm8.542-17.084a3.383 3.383 0 01-3.417-3.416 3.383 3.383 0 013.417-3.417 3.383 3.383 0 013.417 3.417 3.383 3.383 0 01-3.417 3.416zm13.667 0A3.383 3.383 0 0120.5 18.16a3.383 3.383 0 013.417-3.417 3.383 3.383 0 013.416 3.417 3.383 3.383 0 01-3.416 3.416z' fill='%2523000' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 10px;
  background-size: 25px;
  opacity: 0.5;
  content: "";
}


.uppy-Dashboard-Item-preview {
  position: relative;
  width: 50px;
  height: 50px;
}

.uppy-size--lg .uppy-Dashboard-Item-preview {
  height: 120px;
}
.uppy-size--xl .uppy-Dashboard-Item-preview {
  height: 140px;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfoAndButtons{
  padding-right: 8px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfoAndButtons{
  padding-left: 8px;
}

[dir="ltr"] .uppy-Dashboard-Item-fileInfoAndButtons{
  padding-left: 12px;
}

[dir="rtl"] .uppy-Dashboard-Item-fileInfoAndButtons{
  padding-right: 12px;
}

.uppy-Dashboard-Item-fileInfoAndButtons {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}


.uppy-Dashboard-Item-fileInfo {
  flex-grow: 1;
  flex-shrink: 1;
}

.uppy-Dashboard-Item-actionWrapper {
  flex-grow: 0;
  flex-shrink: 0;
}

.uppy-Dashboard-Item.is-inprogress .uppy-Dashboard-Item-previewInnerWrap::after,
.uppy-Dashboard-Item.is-error .uppy-Dashboard-Item-previewInnerWrap::after {
  display: block;
}

.uppy-Dashboard-Item.is-inprogress:not(.is-resumable) .uppy-Dashboard-Item-action--remove {
  display: none;
}

[dir="ltr"] .uppy-Dashboard-Item-errorDetails{
  left: 6px;
}

[dir="rtl"] .uppy-Dashboard-Item-errorDetails{
  right: 6px;
}

.uppy-Dashboard-Item-errorDetails {
  position: relative;
  top: -1px;
  display: inline-block;
  width: 12px;
  height: 12px;
  color: #fff;
  font-weight: 600;
  font-size: 8px;
  line-height: 12px;
  text-align: center;
  vertical-align: middle;
  background-color: #939393;
  border-radius: 50%;
  cursor: help;
  -webkit-appearance: none;
          appearance: none;
}

.uppy-Dashboard-Item-errorDetails::after {
  line-height: 1.3;
  word-wrap: break-word;
}

.uppy-Dashboard-FileCard {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: #fff;
  border-radius: 5px;
  box-shadow: 0 0 10px 4px rgba(0, 0, 0, 0.1);
}
.uppy-Dashboard-FileCard .uppy-DashboardContent-bar {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.uppy-Dashboard-FileCard .uppy-Dashboard-FileCard-actions {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.uppy-Dashboard-FileCard-inner {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-shrink: 1;
  height: 100%;
  min-height: 0;
}

.uppy-Dashboard-FileCard-preview {
  position: relative;
  display: flex;
  flex-grow: 0;
  flex-shrink: 1;
  align-items: center;
  justify-content: center;
  height: 60%;
  min-height: 0;
  border-bottom: 1px solid #eaeaea;
}
[data-uppy-theme=dark] .uppy-Dashboard-FileCard-preview {
  background-color: #333;
  border-bottom: 0;
}

.uppy-Dashboard-FileCard-preview img.uppy-Dashboard-Item-previewImg {
  flex: 0 0 auto;
  max-width: 90%;
  max-height: 90%;
  object-fit: cover;
  border-radius: 3px;
  box-shadow: 0 3px 20px rgba(0, 0, 0, 0.15);
}

[dir="ltr"] .uppy-Dashboard-FileCard-edit{
  right: 10px;
}

[dir="rtl"] .uppy-Dashboard-FileCard-edit{
  left: 10px;
}

.uppy-Dashboard-FileCard-edit {
  position: absolute;
  top: 10px;
  padding: 7px 15px;
  color: #fff;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.5);
  border-radius: 50px;
}
.uppy-Dashboard-FileCard-edit:focus {
  outline: none;
}
.uppy-Dashboard-FileCard-edit::-moz-focus-inner {
  border: 0;
}
.uppy-Dashboard-FileCard-edit:focus {
  box-shadow: 0 0 0 3px rgba(34, 117, 215, 0.5);
}
.uppy-Dashboard-FileCard-edit:hover {
  background-color: rgba(0, 0, 0, 0.8);
}

.uppy-Dashboard-FileCard-info {
  flex-grow: 0;
  flex-shrink: 0;
  height: 40%;
  padding: 30px 20px 20px 20px;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
[data-uppy-theme=dark] .uppy-Dashboard-FileCard-info {
  background-color: #1f1f1f;
}

.uppy-Dashboard-FileCard-fieldset {
  max-width: 640px;
  margin: auto;
  margin-bottom: 12px;
  padding: 0;
  font-size: 0;
  border: 0;
}

.uppy-Dashboard-FileCard-label {
  display: inline-block;
  width: 22%;
  color: #525252;
  font-size: 12px;
  vertical-align: middle;
}

[data-uppy-theme=dark] .uppy-Dashboard-FileCard-label {
  color: #eaeaea;
}

.uppy-Dashboard-FileCard-input {
  display: inline-block;
  width: 78%;
  vertical-align: middle;
}

.uppy-Dashboard-FileCard-actions {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  align-items: center;
  height: 55px;
  padding: 0 15px;
  background-color: #fafafa;
  border-top: 1px solid #eaeaea;
}

[data-uppy-theme=dark] .uppy-Dashboard-FileCard-actions {
  background-color: #1f1f1f;
  border-top: 1px solid #333;
}

[dir="ltr"] .uppy-Dashboard-FileCard-actionsBtn{
  margin-right: 10px;
}

[dir="rtl"] .uppy-Dashboard-FileCard-actionsBtn{
  margin-left: 10px;
}

.uppy-transition-slideDownUp-enter {
  transform: translate3d(0, -105%, 0);
  opacity: 0.01;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

.uppy-transition-slideDownUp-enter.uppy-transition-slideDownUp-enter-active {
  transform: translate3d(0, 0, 0);
  opacity: 1;
}

.uppy-transition-slideDownUp-leave {
  transform: translate3d(0, 0, 0);
  opacity: 1;
  transition: transform 0.25s ease-in-out, opacity 0.25s ease-in-out;
}

.uppy-transition-slideDownUp-leave.uppy-transition-slideDownUp-leave-active {
  transform: translate3d(0, -105%, 0);
  opacity: 0.01;
}

@keyframes uppy-Dashboard-fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes uppy-Dashboard-fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes uppy-Dashboard-slideDownAndFadeIn {
  from {
    transform: translate3d(-50%, -70%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(-50%, -50%, 0);
    opacity: 1;
  }
}
@keyframes uppy-Dashboard-slideDownAndFadeIn--small {
  from {
    transform: translate3d(0, -20%, 0);
    opacity: 0;
  }
  to {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
}
@keyframes uppy-Dashboard-slideUpFadeOut {
  from {
    transform: translate3d(-50%, -50%, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(-50%, -70%, 0);
    opacity: 0;
  }
}
@keyframes uppy-Dashboard-slideUpFadeOut--small {
  from {
    transform: translate3d(0, 0, 0);
    opacity: 1;
  }
  to {
    transform: translate3d(0, -20%, 0);
    opacity: 0;
  }
}
.uppy-Dashboard--modal {
  z-index: 1001;
}

.uppy-Dashboard--modal[aria-hidden=true] {
  display: none;
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
  animation: uppy-Dashboard-slideDownAndFadeIn--small 0.3s cubic-bezier(0, 0, 0.2, 1);
}
@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-inner {
    animation: uppy-Dashboard-slideDownAndFadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose > .uppy-Dashboard-overlay {
  animation: uppy-Dashboard-fadeIn 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
  animation: uppy-Dashboard-slideUpFadeOut--small 0.3s cubic-bezier(0, 0, 0.2, 1);
}
@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-inner {
    animation: uppy-Dashboard-slideUpFadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
  }
}

.uppy-Dashboard--modal.uppy-Dashboard--animateOpenClose.uppy-Dashboard--isClosing > .uppy-Dashboard-overlay {
  animation: uppy-Dashboard-fadeOut 0.3s cubic-bezier(0, 0, 0.2, 1);
}

.uppy-Dashboard-isFixed {
  height: 100vh;
  overflow: hidden;
}

.uppy-Dashboard--modal .uppy-Dashboard-overlay {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1001;
  background-color: rgba(0, 0, 0, 0.5);
}

.uppy-Dashboard-inner {
  position: relative;
  max-width: 100%;
  max-height: 100%;
  background-color: #fafafa;
  border: 1px solid #eaeaea;
  border-radius: 5px;
  outline: none;
}

@media only screen and (min-width: 820px) {
  .uppy-Dashboard-inner {
    width: 750px;
    height: 550px;
  }
}
.uppy-Dashboard--modal .uppy-Dashboard-inner {
  z-index: 1002;
}
[data-uppy-theme=dark] .uppy-Dashboard-inner {
  background-color: #1f1f1f;
}
.uppy-Dashboard--isDisabled .uppy-Dashboard-inner {
  cursor: not-allowed;
}

.uppy-Dashboard-innerWrap {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  border-radius: 5px;
  opacity: 0;
}
.uppy-Dashboard--isInnerWrapVisible .uppy-Dashboard-innerWrap {
  opacity: 1;
}
.uppy-Dashboard--isDisabled .uppy-Dashboard-innerWrap {
  opacity: 0.6;
  filter: grayscale(100%);
  -webkit-user-select: none;
          user-select: none;
  pointer-events: none;
}

.uppy-Dashboard--isDisabled .uppy-ProviderIconBg {
  fill: #9f9f9f;
}

.uppy-Dashboard--modal .uppy-Dashboard-inner {
  position: fixed;
  top: 35px;
  right: 15px;
  bottom: 15px;
  left: 15px;
  border: none;
}
@media only screen and (min-width: 820px) {
  .uppy-Dashboard--modal .uppy-Dashboard-inner {
    top: 50%;
    right: auto;
    left: 50%;
    box-shadow: 0 5px 15px 4px rgba(0, 0, 0, 0.15);
    transform: translate(-50%, -50%);
  }
}

[dir="ltr"] .uppy-Dashboard-close{
  right: -2px;
}

[dir="rtl"] .uppy-Dashboard-close{
  left: -2px;
}

.uppy-Dashboard-close {
  position: absolute;
  top: -33px;
  z-index: 1005;
  display: block;
  color: rgba(255, 255, 255, 0.9);
  font-size: 27px;
  cursor: pointer;
}
.uppy-Dashboard-close:focus {
  outline: none;
}
.uppy-Dashboard-close::-moz-focus-inner {
  border: 0;
}
.uppy-Dashboard-close:focus {
  color: #8cb8ed;
}
@media only screen and (min-width: 820px) {
  [dir="ltr"] .uppy-Dashboard-close{
    right: -35px;
  }
  [dir="rtl"] .uppy-Dashboard-close{
    left: -35px;
  }
  .uppy-Dashboard-close {
    top: -10px;
    font-size: 35px;
  }
}

.uppy-Dashboard-serviceMsg {
  position: relative;
  top: -1px;
  z-index: 1004;
  padding: 12px 0;
  font-weight: 500;
  font-size: 12px;
  line-height: 1.3;
  background-color: #fffbf7;
  border-top: 1px solid #edd4b9;
  border-bottom: 1px solid #edd4b9;
}

[data-uppy-theme=dark] .uppy-Dashboard-serviceMsg {
  color: #eaeaea;
  background-color: #1f1f1f;
  border-top: 1px solid #333;
  border-bottom: 1px solid #333;
}

.uppy-Dashboard-serviceMsg-title {
  display: block;
  margin-bottom: 4px;
  padding-left: 42px;
  line-height: 1;
}

.uppy-Dashboard-serviceMsg-text {
  padding: 0 15px;
}

.uppy-Dashboard-serviceMsg-actionBtn {
  color: #2275d7;
  font-weight: inherit;
  font-size: inherit;
  vertical-align: initial;
}
[data-uppy-theme=dark] .uppy-Dashboard-serviceMsg-actionBtn {
  color: rgba(2, 186, 242, 0.9);
}

.uppy-Dashboard-serviceMsg-icon {
  position: absolute;
  top: 10px;
  left: 15px;
}

.uppy-Dashboard-AddFiles {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  text-align: center;
}
[data-uppy-drag-drop-supported=true] .uppy-Dashboard-AddFiles {
  height: calc(100% - 14px);
  margin: 7px;
  border: 1px dashed #dfdfdf;
  border-radius: 3px;
}
.uppy-Dashboard-AddFilesPanel .uppy-Dashboard-AddFiles {
  height: calc(100% - 14px - 40px);
  border: none;
}
.uppy-Dashboard--modal .uppy-Dashboard-AddFiles {
  border-color: #cfcfcf;
}
[data-uppy-theme=dark] .uppy-Dashboard-AddFiles {
  border-color: #757575;
}

.uppy-Dashboard-AddFiles-info {
  display: none;
  margin-top: auto;
  padding-top: 15px;
  padding-bottom: 15px;
}
.uppy-size--height-md .uppy-Dashboard-AddFiles-info {
  display: block;
}

[data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-info {
  margin-top: 0;
}

.uppy-Dashboard-browse {
  color: rgba(34, 117, 215, 0.9);
  cursor: pointer;
}
.uppy-Dashboard-browse:focus {
  outline: none;
}
.uppy-Dashboard-browse::-moz-focus-inner {
  border: 0;
}
.uppy-Dashboard-browse:hover, .uppy-Dashboard-browse:focus {
  border-bottom: 1px solid #2275d7;
}
[data-uppy-theme=dark] .uppy-Dashboard-browse {
  color: rgba(2, 186, 242, 0.9);
}
[data-uppy-theme=dark] .uppy-Dashboard-browse:hover, [data-uppy-theme=dark] .uppy-Dashboard-browse:focus {
  border-bottom: 1px solid #02baf2;
}

.uppy-Dashboard-browseBtn {
  display: block;
  width: 100%;
  margin-top: 8px;
  margin-bottom: 5px;
  font-weight: 500;
  font-size: 14px;
}


.uppy-Dashboard-AddFiles-list {
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  margin-top: 2px;
  padding: 2px 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.uppy-DashboardTab {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #eaeaea;
}
[data-uppy-theme=dark] .uppy-DashboardTab {
  border-bottom: 1px solid #333;
}


.uppy-DashboardTab-btn {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 12px 15px;
  color: #525252;
  background-color: transparent;
  cursor: pointer;
  -webkit-appearance: none;
  appearance: none;
}
.uppy-DashboardTab-btn:focus {
  outline: none;
}
.uppy-DashboardTab-btn::-moz-focus-inner {
  border: 0;
}

[data-uppy-theme=dark] .uppy-DashboardTab-btn {
  color: #eaeaea;
}

.uppy-DashboardTab-btn::-moz-focus-inner {
  border: 0;
}

.uppy-DashboardTab-btn:hover {
  background-color: #f1f3f6;
}
[data-uppy-theme=dark] .uppy-DashboardTab-btn:hover {
  background-color: #333;
}

.uppy-DashboardTab-btn:active,
.uppy-DashboardTab-btn:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-DashboardTab-btn:active,
[data-uppy-theme=dark] .uppy-DashboardTab-btn:focus {
  background-color: #525252;
}

[dir="ltr"] .uppy-DashboardTab-btn svg{
  margin-right: 10px;
}

[dir="rtl"] .uppy-DashboardTab-btn svg{
  margin-left: 10px;
}

.uppy-DashboardTab-btn svg {
  display: inline-block;
  max-width: 100%;
  max-height: 100%;
  overflow: hidden;
  vertical-align: text-top;
  transition: transform ease-in-out 0.15s;
}


.uppy-DashboardTab-name {
  font-weight: 500;
  font-size: 14px;
}


.uppy-DashboardTab svg {
  width: 23px;
  height: 23px;
  vertical-align: middle;
}


.uppy-Dashboard-input {
  position: absolute;
  z-index: -1;
  width: 0.1px;
  height: 0.1px;
  overflow: hidden;
  opacity: 0;
}

.uppy-DashboardContent-bar {
  position: relative;
  z-index: 1004;
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 40px;
  padding: 0 10px;
  background-color: #fafafa;
  border-bottom: 1px solid #eaeaea;
}

[data-uppy-theme=dark] .uppy-DashboardContent-bar {
  background-color: #1f1f1f;
  border-bottom: 1px solid #333;
}

.uppy-DashboardContent-title {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  max-width: 170px;
  margin: auto;
  overflow-x: hidden;
  font-weight: 500;
  font-size: 12px;
  line-height: 40px;
  white-space: nowrap;
  text-align: center;
  text-overflow: ellipsis;
}

[data-uppy-theme=dark] .uppy-DashboardContent-title {
  color: #eaeaea;
}

[dir="ltr"] .uppy-DashboardContent-back,[dir="ltr"] 
.uppy-DashboardContent-save{
  margin-left: -6px;
}

[dir="rtl"] .uppy-DashboardContent-back,[dir="rtl"] 
.uppy-DashboardContent-save{
  margin-right: -6px;
}

.uppy-DashboardContent-back,
.uppy-DashboardContent-save {
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: 1;
  background: none;
  border: 0;
  -webkit-appearance: none;
  padding: 7px 6px;
  color: #2275d7;
  font-weight: 400;
  font-size: 12px;
  border-radius: 3px;
  cursor: pointer;
}
.uppy-DashboardContent-back:focus,
.uppy-DashboardContent-save:focus {
  outline: none;
}
.uppy-DashboardContent-back::-moz-focus-inner,
.uppy-DashboardContent-save::-moz-focus-inner {
  border: 0;
}
.uppy-DashboardContent-back:hover,
.uppy-DashboardContent-save:hover {
  color: #1b5dab;
}
.uppy-DashboardContent-back:focus,
.uppy-DashboardContent-save:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-DashboardContent-back:focus,
[data-uppy-theme=dark] .uppy-DashboardContent-save:focus {
  background-color: #333;
}

[data-uppy-theme=dark] .uppy-DashboardContent-back,
[data-uppy-theme=dark] .uppy-DashboardContent-save {
  color: #02baf2;
}

[dir="ltr"] .uppy-DashboardContent-addMore{
  margin-right: -5px;
}

[dir="rtl"] .uppy-DashboardContent-addMore{
  margin-left: -5px;
}

.uppy-DashboardContent-addMore {
  margin: 0;
  padding: 0;
  color: inherit;
  font-size: inherit;
  font-family: inherit;
  line-height: 1;
  background: none;
  border: 0;
  -webkit-appearance: none;
  width: 29px;
  height: 29px;
  padding: 7px 8px;
  color: #2275d7;
  font-weight: 500;
  border-radius: 3px;
  cursor: pointer;
}
.uppy-DashboardContent-addMore:focus {
  outline: none;
}
.uppy-DashboardContent-addMore::-moz-focus-inner {
  border: 0;
}
.uppy-DashboardContent-addMore:hover {
  color: #1b5dab;
}
.uppy-DashboardContent-addMore:focus {
  background-color: #eceef2;
}
[data-uppy-theme=dark] .uppy-DashboardContent-addMore:focus {
  background-color: #333;
}

[data-uppy-theme=dark] .uppy-DashboardContent-addMore {
  color: #02baf2;
}

[dir="ltr"] .uppy-DashboardContent-addMore svg{
  margin-right: 4px;
}

[dir="rtl"] .uppy-DashboardContent-addMore svg{
  margin-left: 4px;
}

.uppy-DashboardContent-addMore svg {
  vertical-align: baseline;
}


.uppy-DashboardContent-addMoreCaption {
  display: none;
}


.uppy-DashboardContent-panel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: flex;
  flex: 1;
  flex-direction: column;
  overflow: hidden;
  background-color: whitesmoke;
  border-radius: 5px;
}

.uppy-Dashboard-AddFilesPanel {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1005;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  background: #fafafa;
  background: linear-gradient(0deg, #fafafa 35%, rgba(250, 250, 250, 0.85) 100%);
  border-radius: 5px;
  box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.15);
}
[data-uppy-theme=dark] .uppy-Dashboard-AddFilesPanel {
  background-color: #333;
  background-image: linear-gradient(0deg, #1f1f1f 35%, rgba(31, 31, 31, 0.85) 100%);
}

.uppy-Dashboard--isAddFilesPanelVisible .uppy-Dashboard-files {
  filter: blur(2px);
}

.uppy-Dashboard-progress {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 12%;
}

.uppy-Dashboard-progressBarContainer.is-active {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1004;
  width: 100%;
  height: 100%;
}

.uppy-Dashboard-filesContainer {
  position: relative;
  flex: 1;
  margin: 0;
  overflow-y: hidden;
}
.uppy-Dashboard-filesContainer::after {
  display: table;
  clear: both;
  content: "";
}

.uppy-Dashboard-files {
  flex: 1;
  margin: 0;
  padding: 0 0 10px 0;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}

.uppy-Dashboard-dropFilesHereHint {
  position: absolute;
  top: 7px;
  right: 7px;
  bottom: 7px;
  left: 7px;
  z-index: 2000;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 90px;
  color: #757575;
  font-size: 16px;
  text-align: center;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%232275D7' fill-rule='nonzero'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  border: 1px dashed #2275d7;
  border-radius: 3px;
  visibility: hidden;
}
[data-uppy-theme=dark] .uppy-Dashboard-dropFilesHereHint {
  color: #bbb;
  background-image: url("data:image/svg+xml,%3Csvg width='48' height='48' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M24 1v1C11.85 2 2 11.85 2 24s9.85 22 22 22 22-9.85 22-22S36.15 2 24 2V1zm0 0V0c13.254 0 24 10.746 24 24S37.254 48 24 48 0 37.254 0 24 10.746 0 24 0v1zm7.707 19.293a.999.999 0 1 1-1.414 1.414L25 16.414V34a1 1 0 1 1-2 0V16.414l-5.293 5.293a.999.999 0 1 1-1.414-1.414l7-7a.999.999 0 0 1 1.414 0l7 7z' fill='%2302BAF2' fill-rule='nonzero'/%3E%3C/svg%3E");
  border-color: #02baf2;
}

.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-dropFilesHereHint {
  visibility: visible;
}
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-DashboardContent-bar,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-files,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-progressindicators,
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-serviceMsg {
  opacity: 0.15;
}
.uppy-Dashboard.uppy-Dashboard--isDraggingOver .uppy-Dashboard-AddFiles {
  opacity: 0.03;
}

.uppy-Dashboard-AddFiles-title {
  width: 100%;
  margin-top: 15px;
  margin-bottom: 5px;
  padding: 0 15px;
  color: #000;
  font-weight: 500;
  font-size: 17px;
  line-height: 1.35;
  text-align: inline-start;
}

[data-uppy-num-acquirers="0"] .uppy-Dashboard-AddFiles-title {
  text-align: center;
}
[data-uppy-theme=dark] .uppy-Dashboard-AddFiles-title {
  color: #eaeaea;
}
.uppy-Dashboard-AddFiles-title button {
  font-weight: 500;
}


.uppy-Dashboard-note {
  max-width: 350px;
  margin: auto;
  padding: 0 15px;
  color: #757575;
  font-size: 14px;
  line-height: 1.25;
  text-align: center;
}

[data-uppy-theme=dark] .uppy-Dashboard-note {
  color: #cfcfcf;
}

a.uppy-Dashboard-poweredBy {
  display: inline-block;
  margin-top: 8px;
  color: #939393;
  font-size: 11px;
  text-align: center;
  text-decoration: none;
}

.uppy-Dashboard-poweredByIcon {
  position: relative;
  top: 1px;
  margin-right: 1px;
  margin-left: 1px;
  vertical-align: text-top;
  opacity: 0.9;
  fill: none;
  stroke: #939393;
}

.uppy-Dashboard-Item-previewIcon {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 100;
  width: 25px;
  height: 25px;
  transform: translate(-50%, -50%);
}

.uppy-Dashboard-Item-previewIcon svg {
  width: 100%;
  height: 100%;
}

.uppy-Dashboard-Item-previewIconWrap {
  position: relative;
  height: 76px;
  max-height: 75%;
}

.uppy-Dashboard-Item-previewIconBg {
  width: 100%;
  height: 100%;
  filter: drop-shadow(rgba(0, 0, 0, 0.1) 0 1px 1px);
}

.uppy-Dashboard-upload {
  position: relative;
  width: 50px;
  height: 50px;
}


.uppy-Dashboard-upload .uppy-c-icon {
  position: relative;
  top: 1px;
  width: 50%;
}

[dir="ltr"] .uppy-Dashboard-uploadCount{
  right: -12px;
}

[dir="rtl"] .uppy-Dashboard-uploadCount{
  left: -12px;
}

.uppy-Dashboard-uploadCount {
  position: absolute;
  top: -12px;
  width: 16px;
  height: 16px;
  color: #fff;
  font-size: 8px;
  line-height: 16px;
  background-color: #1bb240;
  border-radius: 50%;
}