
.pagination {
    display: flex;
    align-items: center;
}

.list {
    align-items: center;
    list-style: none;
    margin: 0 -11px;
    padding: 0;
    display: flex;
}


.listItem {
    display: flex;
    align-items: center;
    margin: 3px 13px;
    border-bottom: 2px solid transparent;
    padding-bottom: .25em;
    font-family: 'gt-america';
    font-size: 14px;
    opacity: 0.5;
}

.listItem button {
    background-color: transparent;
    font: inherit;
    border: 0;
    padding: 0;
    /* cursor: pointer; */
    color: #fff;
    font-weight: bold;
    display: flex;
    align-items: center;
    font-size: 14px;
    position: relative;
}

.listItem button span {
    position: absolute;
    top: calc(100% + 4px);
    left: 0;
    right: 0;
    height: 2px;
    background-color: #fff;
}

.listItemActive {
    opacity: 1
}

.indicator {
    width: 26px;
    height: 26px;
    background-color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    border-radius: 10px;
    margin-right: 10px;
    
}

@media(min-width: 768px) {
    .indicator {
        display: none;
    }
    .list {
        display: flex;
    }
    .listItem {
        margin-top: 0;
        margin-bottom: 0;
    }
    .pagination {
        justify-content: flex-start;
        margin-bottom: 30px;
    }
}

@media(min-width: 1165px) {
    .indicator {
        display: flex;
    }
}