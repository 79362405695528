.uppy-Dashboard-inner {
    background-color: #FFF;
    width: auto!important;
}

.uppy-Dashboard-AddFiles-title {
    font-size: 14px;
    font-family: 'gt-america';
}

.uppy-DashboardContent-bar {
    background-color: #FFF;
    padding: 5px 10px;
    height: auto;
}

.uppy-DashboardContent-bar > div:nth-child(1) {
    display: none;
}

.uppy-DashboardContent-title {
    position: static;
    margin-left: 0;
    max-width: initial;
    width: auto;
    font-size: 16px;
    font-family: 'gt-america';
}

.uppy-DashboardContent-addMore {
    width: 40px;
    height: 40px;
    background: #F5F6F7;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

[dir="ltr"] .uppy-DashboardContent-addMore svg { 
    margin: 0; 
    display: block;
}
[dir="ltr"] .uppy-DashboardContent-addMore svg path { fill: #0D0E10; }

.uppy-Dashboard-Item-name {
    font-family: 'gt-america';
    font-size: 14px;
    line-height: 145%;
    color: #5B5E64;
}
.uppy-Dashboard-Item-status { display: none; }
.uppy-Dashboard-Item-action--remove {
    height: 26px!important;
    width: 26px!important;
    margin-right: 4px;
}
.uppy-Dashboard-Item-action--remove svg { width: 100%; height: 100%; }
.uppy-Dashboard-Item-action--remove svg path:nth-child(1) {
    display: none;
}
.uppy-Dashboard-Item-action--remove svg path:nth-child(2) {
    fill: #0D0E10;
}

.uppy-Dashboard-browse {
    font-family: 'gt-america';
}