.poster.orange .posterFront, .poster.orange .posterBack { background-color: #F58349; }
.poster.green .posterFront, .poster.green .posterBack { background-color: #91C662; }
.poster.yellow .posterFront, .poster.yellow .posterBack { background-color: #F7B71A; }
.poster.red .posterFront, .poster.red .posterBack { background-color: #E8564F; }
.poster.pink .posterFront, .poster.pink .posterBack { background-color: #EC93CE; }
.poster.blue .posterFront, .poster.blue .posterBack { background-color: #7AC3E7; }


.poster {
    --width: 760px;
    height: calc(var(--width) * 1);
    width: var(--width);
    position: relative;
    transition: 500ms background;
    perspective: 1000px;
    margin: 0 auto;
    border-radius: 4px;
}

.posterInner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.8s;
    transform-style: preserve-3d;
    
}

.posterContent {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.container {
    margin: 0 auto;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
}

.title {
    font-size: calc(calc(var(--width) * 0.1) * var(--fontScale));
    font-family: 'caslon';
    font-weight: 400;
    text-transform: uppercase;
    color: #FFF;
    line-height: 0.7;
    position: relative;
    z-index: 2;
    align-self: flex-start;
}

.titleExt {
    position: absolute;
    top: calc(100% + calc(calc(var(--width) * 0.034)));
    left: 0;
    text-transform: lowercase;
    font-family: 'gt-america';
    font-size: calc(calc(var(--width) * 0.074));
    margin-left: calc(0px - var(--width)*0.007);
}


.svgWrap {
    flex: 1;
    position: relative;
    font-family: 'caslon';
    align-items: flex-end;
    display: flex;
    font-size: calc(var(--width)*0.32);
    line-height: 1;
    height: 0;
    padding-top: calc(var(--width)*0.05);
}

.svgWrap span {
    transform-origin: bottom;
}

.svgWrap img {
    height: calc( 100% - (var(--width) * 0.05));
    width: 100%;
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
}

.svgWrap svg {
    width: 100%;
    height: 100%;
}

.titleWrapper {
    width: 100%;
    display: block;
}

.highlight {
    color: #FCF7DF;
}

.button {
    background: #FCF7DF;
    border-radius: calc(var(--width) * 0.01333333333);
    width: 100%;
    height: calc(var(--width) * 0.1533333333);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: calc(var(--width) * 0.04666666667);
    position: relative;
    z-index: 2;
}


.posterBack, .posterFront {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden; /* Safari */
    backface-visibility: hidden;
    background-color: #ddd;
    padding: calc(var(--width) * 0.05) calc(var(--width) * 0.038);
    border-radius: 4px;

}

.posterFront {
    background-color: #bbb;
}


.posterBack {
    transform: rotateY(180deg);
    background-color: dodgerblue;
    font-size: calc(calc(var(--width) * 0.073) * var(--fontScale));
    line-height: 1.35;
    text-align: left;
    padding: calc(var(--width) * 0.065) calc(var(--width) * 0.06);
    word-break: break-word;
    z-index: 2;
}

.poster.rotated .posterInner {
    transform: rotateY(180deg);
}

.footer {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: calc(var(--width) * 0.03)
}

.footerText {
    font-size: calc(var(--width) * 0.149);
    font-family: 'caslon';
    font-weight: 400;
    text-transform: uppercase;
    color: #FFF;
    line-height: 0.7;
}

.footerLogo {
    width: calc(var(--width) * 0.1);
    height: calc(var(--width) * 0.1);
}

.landscapeHolder {
    background-color: var(--primary);
    width: 1200px;
    height: 630px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    right: -9999px;
    top: 0;
}

.logo {
    width: calc(var(--width) * 0.25);
}