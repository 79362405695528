.preview-container .size-A3,
.preview-container .size-A4,
.preview-container .size-square,
.preview-container .size-landscape,
.preview-container .size-social,
.preview-container .size-email
 {
    --width: 69vmin;
}

@media(min-width: 767px) {
    .preview-container .size-A3,
    .preview-container .size-A4,
    .preview-container .size-square,
    .preview-container .size-landscape,
    .preview-container .size-social,
    .preview-container .size-email
     {
        --width: 56vmin;
    }
}

.preview-render {
    pointer-events: none;
    width: 1200px;
    height: 620px;
    background-color: var(--primary);
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    z-index: 2;
    position: absolute;
    left: -9999px;
}
.preview-render-small {
    pointer-events: none;
    z-index: 2;
    position: absolute;
    left: -9999px;
    top: -9999px
}
.preview-render .size-A3 { --width: 297mm; }
.preview-render .size-A4 { --width: 210mm; }
.preview-render .size-square { --width: 480px; }
.preview-render .size-landscape { --width: 728px; }
.preview-render .size-social { --width: 1080px; }
.preview-render .size-email { --width: 600px; }
