.container {
}

.container textarea {
    font-size: 14px;
    line-height: 1.4;

    font-family: 'gt-america';
    background: transparent;
    border: 1px solid #fff;
    color: #fff;
    padding: 1em;
    border-radius: 4px;
    width: 100%;
    outline: none;
    height: 125px;
    resize: none;
}

.container textarea::placeholder {
    color: rgba(255, 255, 255, 0.5);
}

.fontSizeBox {
    margin-top: 20px;
}

.fontSizes {
    display: inline-flex;
    margin: 0 -5px;
    align-items: center;
}

.fontSizeOption {
    background-color: transparent;
    padding: 0;
    cursor: pointer;
    border-radius: 50%;
    background-color: #FFF;
    border: 0;
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 5px;
}

.fontSizeOption:hover { 
    background-color: #0B0B0B; 
    border-color: #0B0B0B; 
}

.fontSizeOption:hover svg path {
    stroke: #FFF;
}

.fontSizeOption:disabled {
    pointer-events: none;
    opacity: 0.25;
}

.currentFontSize {
    width: 50px;
    text-align: center;
    font-weight: 500;
}